import { useQuery } from '@tanstack/react-query'
import { DefaultService, GenerateApproachRequest } from '@/api/core'

export const queryKeys = {
  enrichAccount: (accountDomainName: string, jobTitles: string) =>
    ['enrichAccount', accountDomainName, jobTitles] as const,
  generateApproach: (body: GenerateApproachRequest) => ['generateApproach', JSON.stringify(body)] as const,
}

export function useSequenceGenerateEnrichAccount(accountDomainName: string, jobTitles: string) {
  return useQuery({
    queryKey: queryKeys.enrichAccount(accountDomainName, jobTitles),
    enabled: accountDomainName != '' && jobTitles != '',
    queryFn: () => DefaultService.getSequenceGenerationAccountEnrichment(accountDomainName, jobTitles),
  })
}

export function useSequenceGenerateApproach(body: GenerateApproachRequest) {
  return useQuery({
    queryKey: queryKeys.generateApproach(body),
    enabled: body.seller.description != '' && body.seller.valuePropositions.join(',') != '',
    queryFn: () => DefaultService.postSequenceGenerationApproach(body),
  })
}
